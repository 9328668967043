

body {
  position: fixed
}

.category-item {
  border-radius: 1*$unitMult+$unit;
}


.kiosk-bar {
  // position: absolute;
  // bottom:0;
  // right: 0;
  // left: 0;
  height: 25px;
  background-color: #3e3a3b;
}



.start .main .copy {
  bottom: 25px;
}


.start .banner {
  background-image: url("../../images/client/best-splash.jpeg");
  background-position: center;
  background-size: cover;
}
